import { useMQTT } from "mqtt-vue-hook";
export default {
  namespaced: true,

  state: {
    mqttInstance: null,
    mqttClient: null,
    mqttConnectionString: null,
    mqttUserName: null,
    mqttPassword: null,
    mqttRole: null,
    mqttUserTopic: null,
    mqttIsInstanceConnected: 0,
    mqttIsLoginPage: 0,
    mqttIsLoginPageUpdated: 0,
    mqttLoginMode: true,
  },
  getters: {
    getMqttConnectionString: (state) => {
      return state.mqttConnectionString;
    },
    getMqttUserName: (state) => {
      return state.mqttUserName;
    },
    getMqttPassword: (state) => {
      return state.mqttPassword;
    },
    getMqttRole: (state) => {
      return state.mqttRole;
    },
    getMqttUserTopic: (state) => {
      return state.mqttUserTopic;
    },
    getMqttInstance: (state) => {
      return state.mqttInstance;
    },
    getMqttClient: (state) => {
      return state.mqttClient;
    },
    getMqttIsInstanceConnected: (state) => {
      return state.mqttIsInstanceConnected;
    },
    getMqttIsLoginPage: (state) => {
      return state.mqttIsLoginPage;
    },
    getMqttIsLoginPageUpdated: (state) => {
      return state.mqttIsLoginPageUpdated;
    },
    getMqttLoginMode: (state) => {
      return state.mqttLoginMode;
    },
  },

  mutations: {
    setMqttConnectionString: (state, val) => {
      state.mqttConnectionString = val;
    },
    setMqttUserName: (state, val) => {
      state.mqttUserName = val;
    },
    setMqttPassword: (state, val) => {
      state.mqttPassword = val;
    },
    setMqttRole: (state, val) => {
      state.mqttRole = val;
    },
    setMqttUserTopic: (state, val) => {
      state.mqttUserTopic = val;
    },
    setMqttInstance: (state, val) => {
      state.mqttInstance = val;
    },
    setMqttClient: (state, val) => {
      state.mqttClient = val;
    },
    setMqttIsInstanceConnected: (state, val) => {
      state.mqttIsInstanceConnected = val;
    },
    setMqttIsLoginPage: (state, val) => {
      state.mqttIsLoginPage = val;
    },
    setMqttLoginMode: (state, val) => {
      state.mqttLoginMode = val;
    },
    setMqttIsLoginPageUpdated: (state, val) => {
      state.mqttIsLoginPageUpdated += val;
    },
  },

  actions: {
    mqttConnect(context) {
      let mqttInstance = useMQTT();
      let connectionString = context.getters.getMqttConnectionString;
      let userName = context.getters.getMqttUserName;
      let password = context.getters.getMqttPassword;

      mqttInstance.registerEvent("on-connect", () => {
        context.commit("setMqttIsInstanceConnected", 1);
        // eslint-disable-next-line
        console.log(`MQTT on-connect`);
      });
      mqttInstance.registerEvent("on-disconnect", () => {
        context.commit("setMqttIsInstanceConnected", 0);
        // eslint-disable-next-line
        console.log(`MQTT on-disconnect`);
      });
      mqttInstance.registerEvent("on-reconnect", () => {
        context.commit("setMqttIsInstanceConnected", 0);
        // eslint-disable-next-line
        console.log(`MQTT on-reconnect`);
      });
      mqttInstance.registerEvent("on-connect-fail", () => {
        context.commit("setMqttIsInstanceConnected", 0);
        // eslint-disable-next-line
         console.log(`MQTT on-connect-fail`);
      });

      let mqttClient = mqttInstance.connect(connectionString, {
        username: userName,
        password: password,
        clean: true,
        keepalive: 60,
        clientId: `mqtt_client_${Math.random().toString(16).substring(2, 10)}`,
        connectTimeout: 4000,
      });
      context.commit("setMqttInstance", mqttInstance);
      context.commit("setMqttClient", mqttClient);
    },
    mqttDisconnect(context) {
      let mqttInstance = context.getters.getMqttInstance;
      mqttInstance.disconnect();
    },
  },
};
