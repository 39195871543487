<template>
  <div v-if="getNewNetwork" :style="wifiStyle">
    <p style="margin: 1vh 2vw 1vh 2vw">
      Please reconnect to "{{ name }}" network...
    </p>
  </div>
  <div v-else :style="wifiStyle">
    <div>
      <button id="button" class="button" @:click="handleModeClick">
        {{ getNetworkMode }}
      </button>
    </div>
    <div>
      <switches
        color="default"
        style="margin: 0vh 2vw 0vh 2vw"
        :value="nameSwitchStatus"
        @:click="toggleNameForm()"
      ></switches>
      <label class="label">Network Name</label>
    </div>
    <div>
      <input
        v-if="nameSwitchStatus"
        type="text"
        class="input"
        v-model="name"
        @input="nameChange"
      />
      <select
        v-else
        class="selector"
        v-model="selected"
        @change="onChange($event)"
      >
        <option v-for="index in getNetworks" :value="index.value" :key="index">
          {{ index.text }}
        </option>
      </select>
    </div>
    <div>
      <switches
        color="default"
        style="margin: 0vh 2vw 0vh 2vw"
        :value="passwordSwitchStatus"
        @:click="toggleShow()"
      ></switches>
      <label class="label">Network Password</label>
    </div>
    <div>
      <form>
        <input
          v-if="passwordSwitchStatus"
          type="text"
          autocomplete="on"
          class="input"
          v-model="password"
          @input="passwordChange"
        />
        <input
          v-else
          type="password"
          autocomplete="on"
          class="input"
          v-model="password"
          @input="passwordChange"
        />
      </form>
    </div>
    <div>
      <button
        id="button"
        class="button"
        v-if="apply"
        @:click="handleApplyClick"
      >
        Apply
      </button>
    </div>
  </div>
</template>
<script>
import AppConstants from "@/AppConstants.js";
import Switches from "/node_modules/vue-switches";
import { mapGetters, mapMutations } from "vuex";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
const ROUTER_CLIENT_NAME = "RouterClient";
const ACCESS_POINT_NAME = "AccessPoint";
let size = AppConstants.get2Size();
export default {
  data() {
    return {
      wifiStyle: {
        position: "absolute",
        background: "black",
        border: "1px solid black",
        left: MENU_WIDTH + "px",
        top: "0vh",
        width: size.width + "vw",
        height: "100vh",
        textAlign: "left",
        color: "white",
      },
      password: null,
      name: null,
      nameSwitchStatus: true,
      passwordSwitchStatus: false,
      selected: 0,
      apply: false,
    };
  },
  components: {
    Switches,
  },
  computed: {
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getMqttUserTopic: "mqttStore/getMqttUserTopic",
      getMqttIsInstanceConnected: "mqttStore/getMqttIsInstanceConnected",
      getNetworkMode: "networkStore/getNetworkMode",
      getNetworks: "networkStore/getNetworks",
      getNewNetwork: "networkStore/getNewNetwork",
    }),
  },
  methods: {
    ...mapMutations({
      setNetworkMode: "networkStore/setNetworkMode",
      setNewNetwork: "networkStore/setNewNetwork",
    }),
    toggleShow() {
      this.passwordSwitchStatus = !this.passwordSwitchStatus;
    },
    toggleNameForm() {
      this.nameSwitchStatus = !this.nameSwitchStatus;
      if (!this.nameSwitchStatus) {
        this.selected = 0;
        this.name = "";
        this.password = "";
        this.apply = false;
      }
    },
    handleModeClick: function () {
      if (this.getNetworkMode == ACCESS_POINT_NAME) {
        this.setNetworkMode(ROUTER_CLIENT_NAME);
        this.nameSwitchStatus = false;
        this.selected = 0;
        this.name = "";
        this.password = "";
        this.apply = false;
      } else if (this.getNetworkMode == ROUTER_CLIENT_NAME) {
        this.setNetworkMode(ACCESS_POINT_NAME);
        this.nameSwitchStatus = true;
        this.name = "SmaLiC";
        this.password = "smartlightcontroller";
        this.apply = true;
      }
    },
    handleApplyClick: function () {
      let message = "{";
      message += "'mode':'" + this.getNetworkMode + "'";
      message += ",'name':'" + this.name + "'";
      message += ",'password':'" + this.password + "'";
      message += "}";
      this.setNewNetwork(true);
      this.mqttInstance.publish(
        this.getMqttUserTopic + "/credentials",
        message,
        0,
        {},
        () => {}
      );
    },
    onChange: function (event) {
      if (event.target.value) {
        this.nameSwitchStatus = true;
        this.name = this.getNetworks[event.target.value].text;
        this.password = "";
      }
    },
    passwordChange: function (event) {
      if (
        event.target.value &&
        this.name &&
        2 <= this.name.length &&
        32 >= this.name.length &&
        8 <= this.password.length &&
        63 >= this.password.length
      ) {
        this.apply = true;
      } else {
        this.apply = false;
      }
    },
    nameChange: function (event) {
      if (
        event.target.value &&
        2 <= this.name.length &&
        32 >= this.name.length &&
        this.password &&
        8 <= this.password.length &&
        63 >= this.password.length
      ) {
        this.apply = true;
      } else {
        this.apply = false;
      }
    },
  },
  mounted() {
    this.setNetworkMode(ROUTER_CLIENT_NAME);
    this.nameSwitchStatus = false;
    this.setNewNetwork(false);
  },
  created() {
    let message = "{";
    message += "'name':'wifi'";
    message += "}";
    if (this.getMqttIsInstanceConnected) {
      this.mqttInstance.publish(
        this.getMqttUserTopic + "/update",
        message,
        0,
        {},
        () => {}
      );
    }
  },
};
</script>
<style scoped>
.input {
  color: white;
  background-color: black;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  width: 100%;
  border: 3px solid #303030;
  padding: 10px;
  border-radius: 25px;
}
.input:hover {
  border: 3px solid white;
}
.input:focus {
  border: 3px solid white;
  outline: none;
}
.label {
  font-size: normal;
  color: lightgrey;
}
.button {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  width: 100%;
  margin: 1vh 0vw 1vh 0vw;
  padding: 10px;
  border-radius: 25px;
}
.button:hover {
  border: 3px solid white;
}
.selector {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  width: 100%;
  margin: 1vh 0vw 1vh 0vw;
  padding: 10px;
  border-radius: 25px;
}
</style>
