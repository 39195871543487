<template>
  <div class="info" :style="infoStyle">
    <p style="margin: 1vh 2vw 1vh 2vw">
      "Dashboard" buttons allow to activate different scenarios providing a
      variety of lighting options. To activate a specific scenario, simply press
      the corresponding button. The system will adjust its brightness and color
      temperature based on the predefined settings.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      The "Configuration" menu option defines the desired player. "Generic"
      player provides the basic functionality to turn the light on and off.
      "Sequence" player enables advanced control by playing frame sequences to
      create light shows accompanied with music. DDP (Dynamic Display Protocol)
      player facilitates the creation and playback of light shows designed with
      a PC sequencer.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      it is possible to customize and assign preferred colors and brightness to
      each scenario using the "Setting" menu option. Navigate and select the
      desired color adjusting RGB values(0-255) and color temperature controls.
      Once the necessary color is selected, click any scenario button or light
      strip to apply the chosen color.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      "Get" button allows to obtain color temperature of a selected component.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      "Set" button allows to assign color temperature to a selected component.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      "Cor" button applies the color temperature correction.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      "Raw" button applies the raw colors without any correction.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      "View" button applies the current scenario settings.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      "Save" button saves the current settings to retentive memory. The selected
      scenario will be activated automatically on the next POWER ON event.
    </p>
    <p style="margin: 0vh 2vw 1vh 2vw">
      There are few applications based on Time-of-Flight (ToF) Sensor. "Motion
      Detector" detects movement and triggers a predefined action, such as
      turning on lights. "Zone Detector" measures the minimum distance to an
      object or person allowing for zone-specific actions. "ToF Sensor"
      application provides detailed information of raw distance data to objects.
    </p>
  </div>
</template>
<script>
import AppConstants from "@/AppConstants.js";
const MENU_WIDTH = AppConstants.MENU_WIDTH;
let size = AppConstants.get2Size();
export default {
  data() {
    return {
      infoStyle: {
        position: "absolute",
        background: "black",
        border: "1px solid black",
        left: MENU_WIDTH + "px",
        top: "0vh",
        width: size.width + "vw",
        height: "100vh",
        textAlign: "left",
        screenWidth: screen.width,
      },
    };
  },
};
</script>
<style scoped>
.info {
  color: white;
  font-size: normal;
}
</style>
