/* icons from https://boxicons.com/ */
const MIN_SCENARIO = 1;
const MAX_SCENARIO = 9;
const WIFI_MENU_ITEM = 4;
function decimalToHex(d, padding) {
  var hex = Number(d).toString(16);
  padding =
    typeof padding === "undefined" || padding === null
      ? (padding = 2)
      : padding;
  while (hex.length < padding) {
    hex = "0" + hex;
  }
  return hex;
}
function truncateString(str, length) {
  var dots = str.length > length ? "..." : "";
  return str.substring(0, length) + dots;
}
export default {
  namespaced: true,
  state: {
    infoMsg: {},
    infoDebugMessage: "Storage Dedug Message",
    blueIdx: 0,
    greenIdx: 1,
    redIdx: 2,
    whiteIdx: 3,
    buttonSelectedScenario: 9,
    scenario: 0,
    colorMode: 0,
    updateCounter: 0,
    isColorSchemaUpdated: 0,
    brushColor: "#000000",
    stripColor: [
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
    ],
    buttonColor: [
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
      "#000000",
    ],
    gamma8: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 3,
      3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8,
      8, 9, 9, 9, 10, 10, 10, 11, 11, 11, 12, 12, 13, 13, 13, 14, 14, 15, 15,
      16, 16, 17, 17, 18, 18, 19, 19, 20, 20, 21, 21, 22, 22, 23, 24, 24, 25,
      25, 26, 27, 27, 28, 29, 29, 30, 31, 32, 32, 33, 34, 35, 35, 36, 37, 38,
      39, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 50, 51, 52, 54, 55,
      56, 57, 58, 59, 60, 61, 62, 63, 64, 66, 67, 68, 69, 70, 72, 73, 74, 75,
      77, 78, 79, 81, 82, 83, 85, 86, 87, 89, 90, 92, 93, 95, 96, 98, 99, 101,
      102, 104, 105, 107, 109, 110, 112, 114, 115, 117, 119, 120, 122, 124, 126,
      127, 129, 131, 133, 135, 137, 138, 140, 142, 144, 146, 148, 150, 152, 154,
      156, 158, 160, 162, 164, 167, 169, 171, 173, 175, 177, 180, 182, 184, 186,
      189, 191, 193, 196, 198, 200, 203, 205, 208, 210, 213, 215, 218, 220, 223,
      225, 228, 231, 233, 236, 239, 241, 244, 247, 249, 252, 255,
    ],
    stripStatus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    buttonStatus: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    buttonAlpha: ["64", "64", "64", "64", "64", "64", "64", "64", "64", "64"],
    menuItems: [
      {
        link: process.env.VUE_APP_PATH,
        name: "Dashboard",
        tooltip: "Dashboard",
        /* icon: "bx-grid-alt", */
        icon: "bxs-color",
      },
      {
        link: process.env.VUE_APP_PATH + "Info",
        name: "Info",
        tooltip: "Info",
        icon: "bx-info-circle",
      },
      {
        link: process.env.VUE_APP_PATH + "Configuration",
        name: "Configuration",
        tooltip: "Configuration",
        icon: "bx-cog",
      },
      {
        link: process.env.VUE_APP_PATH + "Setting",
        name: "Setting",
        tooltip: "Setting",
        icon: "bx-color",
      },
      {
        link: process.env.VUE_APP_PATH + "WiFi",
        name: "WiFi",
        tooltip: "WiFi",
        icon: "bx-wifi",
      },
      {
        link: process.env.VUE_APP_PATH + "LiDAR",
        name: "Applications",
        tooltip: "Applications",
        icon: "bx-radar",
        /* icon: "bx-play-circle", */
        /* icon: "bx-play", */
      },
    ],
  },
  getters: {
    getMenuItems: (state) => {
      return state.menuItems;
    },
    getDebugMessage: (state) => {
      return state.infoDebugMessage;
    },
    getSettingColorByStripIndex: (state) => (stripIdx) => {
      return state.stripColor[stripIdx];
    },
    getSettingColorByButtonIndex: (state) => (btnIdx) => {
      return state.buttonColor[btnIdx];
    },
    getStripStatus: (state) => (stripIdx) => {
      return state.stripStatus[stripIdx];
    },
    getButtonStatus: (state) => (buttonIdx) => {
      return state.buttonStatus[buttonIdx];
    },
    getButtonAlpha: (state) => (buttonIdx) => {
      return state.buttonAlpha[buttonIdx];
    },
    getButtonSelectedScenario: (state) => {
      return state.buttonSelectedScenario;
    },
    getScenario: (state) => {
      if (!Object.prototype.hasOwnProperty.call(state.infoMsg, "status"))
        return 9;
      if (state.scenario) {
        return state.scenario;
      } else {
        return parseInt(state.infoMsg.status);
      }
    },
    getColorByButtonIndex: (state, getters) => (btnIdx) => {
      if (!Object.prototype.hasOwnProperty.call(state.infoMsg, "status"))
        return "#000000";
      if (!Object.prototype.hasOwnProperty.call(state.infoMsg, "colorschema"))
        return "#000000";
      let scenario = getters.getScenario;
      if (MIN_SCENARIO > scenario || MAX_SCENARIO < scenario) return "#000000";
      let blueReg =
        (getters.getScenario - 1) * 64 + (btnIdx - 1) * 4 + state.blueIdx;
      let greenReg =
        (getters.getScenario - 1) * 64 + (btnIdx - 1) * 4 + state.greenIdx;
      let redReg =
        (getters.getScenario - 1) * 64 + (btnIdx - 1) * 4 + state.redIdx;

      blueReg = parseInt(blueReg) * 2;
      redReg = parseInt(redReg) * 2;
      greenReg = parseInt(greenReg) * 2;

      let redCode = state.infoMsg.colorschema.substring(redReg, redReg + 2);
      let greenCode = state.infoMsg.colorschema.substring(
        greenReg,
        greenReg + 2
      );
      let blueCode = state.infoMsg.colorschema.substring(blueReg, blueReg + 2);
      /* -- */
      let blueColor = parseInt(blueCode.toString(), 16);
      let greenColor = parseInt(greenCode.toString(), 16);
      let redColor = parseInt(redCode.toString(), 16);
      if (0xff < blueColor) blueColor = 0xff;
      if (0xff < greenColor) greenColor = 0xff;
      if (0xff < redColor) redColor = 0xff;
      let color =
        "#" +
        decimalToHex(redColor, 2) +
        decimalToHex(greenColor, 2) +
        decimalToHex(blueColor, 2);

      return color;
    },
    getColorByStripIndex: (state, getters) => (stripIdx) => {
      if (!Object.prototype.hasOwnProperty.call(state.infoMsg, "status"))
        return "#000000";
      if (!Object.prototype.hasOwnProperty.call(state.infoMsg, "colorschema"))
        return "#000000";
      let scenario = getters.getScenario;
      if (MIN_SCENARIO > scenario || MAX_SCENARIO < scenario) return "#000000";
      let blueReg =
        32 +
        (getters.getScenario - 1) * 64 +
        (stripIdx - 1) * 4 +
        state.blueIdx;
      let greenReg =
        32 +
        (getters.getScenario - 1) * 64 +
        (stripIdx - 1) * 4 +
        state.greenIdx;
      let redReg =
        32 + (getters.getScenario - 1) * 64 + (stripIdx - 1) * 4 + state.redIdx;
      let whiteReg =
        32 +
        (getters.getScenario - 1) * 64 +
        (stripIdx - 1) * 4 +
        state.whiteIdx;

      blueReg = parseInt(blueReg) * 2;
      redReg = parseInt(redReg) * 2;
      greenReg = parseInt(greenReg) * 2;
      whiteReg = parseInt(whiteReg) * 2;

      let redCode = state.infoMsg.colorschema.substring(redReg, redReg + 2);
      let greenCode = state.infoMsg.colorschema.substring(
        greenReg,
        greenReg + 2
      );
      let blueCode = state.infoMsg.colorschema.substring(blueReg, blueReg + 2);
      let whiteCode = state.infoMsg.colorschema.substring(
        whiteReg,
        whiteReg + 2
      );
      /* -- */
      let blueColor = parseInt(blueCode.toString(), 16);
      let greenColor = parseInt(greenCode.toString(), 16);
      let redColor = parseInt(redCode.toString(), 16);
      let whiteColor = parseInt(whiteCode.toString(), 16);
      if (0xff < blueColor) blueColor = 0xff;
      if (0xff < greenColor) greenColor = 0xff;
      if (0xff < redColor) redColor = 0xff;
      if (0xff < whiteColor) whiteColor = 0xff;
      let color =
        "#" +
        decimalToHex(redColor, 2) +
        decimalToHex(greenColor, 2) +
        decimalToHex(blueColor, 2);
      return color;
    },
    getWhiteColorByStripIndex: (state, getters) => (stripIdx) => {
      if (!Object.prototype.hasOwnProperty.call(state.infoMsg, "status"))
        return "#000000";
      if (!Object.prototype.hasOwnProperty.call(state.infoMsg, "colorschema"))
        return "#000000";
      let scenario = getters.getScenario;
      if (MIN_SCENARIO > scenario || MAX_SCENARIO < scenario) return "#000000";
      let whiteReg =
        32 +
        (getters.getScenario - 1) * 64 +
        (stripIdx - 1) * 4 +
        state.whiteIdx;
      whiteReg = parseInt(whiteReg) * 2;
      let whiteCode = state.infoMsg.colorschema.substring(
        whiteReg,
        whiteReg + 2
      );
      /* -- */
      let whiteColor = parseInt(whiteCode.toString(), 16);
      if (0xff < whiteColor) whiteColor = 0xff;
      let color = "#" + decimalToHex(whiteColor, 2);
      return color;
    },
    getAdjustedColorString: (state) => (colorStr) => {
      let blueCode = colorStr.substring(0, 2);
      let greenCode = colorStr.substring(2, 4);
      let redCode = colorStr.substring(4, 6);
      let whiteCode = colorStr.substring(6, 8);
      /* -- */
      let blueColor = parseInt(blueCode.toString(), 16);
      let greenColor = parseInt(greenCode.toString(), 16);
      let redColor = parseInt(redCode.toString(), 16);
      let whiteColor = parseInt(whiteCode.toString(), 16);
      /* Correct colors */
      blueColor = state.gamma8[blueColor];
      greenColor = state.gamma8[greenColor];
      redColor = state.gamma8[redColor];
      whiteColor = state.gamma8[whiteColor];
      /* Build the corrected BGR+W string */
      let finalColorStr =
        decimalToHex(blueColor, 2) +
        decimalToHex(greenColor, 2) +
        decimalToHex(redColor, 2) +
        decimalToHex(whiteColor, 2);
      /* -- */
      return finalColorStr.toUpperCase();
    },
    getUpdateCounter: (state) => {
      return state.updateCounter;
    },
    getBrushColor: (state) => {
      return state.brushColor;
    },
    getColorMode: (state) => {
      return state.colorMode;
    },
    getIsColorSchemaUpdated: (state) => {
      return state.isColorSchemaUpdated;
    },
  },
  mutations: {
    setInfoMsg: (state, val) => {
      state.infoMsg = val;
      if (Object.prototype.hasOwnProperty.call(state.infoMsg, "colorschema")) {
        state.isColorSchemaUpdated = 1;
      }
    },
    setInfoDebugMsg: (state, val) => {
      state.infoDebugMessage = val;
    },
    setStripColor: (state, val) => {
      state.stripColor[val.index] = val.color;
    },
    setButtonColor: (state, val) => {
      state.buttonColor[val.index] = val.color;
    },
    setScenario: (state, val) => {
      state.scenario = val;
    },
    setStripStatus: (state, val) => {
      state.stripStatus[val.index] = val.status;
    },
    setButtonStatus: (state, val) => {
      state.buttonStatus[val.index] = val.status;
    },
    setButtonAlpha: (state, val) => {
      state.buttonAlpha[val.index] = val.alpha;
    },
    setButtonSelectedScenario: (state, val) => {
      state.buttonSelectedScenario = val;
    },
    setWifiSignalStrength: (state, val) => {
      let wifiNdx = WIFI_MENU_ITEM;
      if ("exelent" == val) {
        state.menuItems[wifiNdx].icon = "bx-wifi";
      } else if ("good" == val) {
        state.menuItems[wifiNdx].icon = "bx-wifi-2";
      } else if ("poor" == val) {
        state.menuItems[wifiNdx].icon = "bx-wifi-1";
      } else {
        state.menuItems[wifiNdx].icon = "bx-wifi-off";
      }
      state.menuItems[wifiNdx].name = "My Network";
    },
    setWifiName: (state, val) => {
      let wifiNdx = WIFI_MENU_ITEM;
      state.menuItems[wifiNdx].name = "WiFi(" + truncateString(val, 10) + ")";
    },
    setBrushColor: (state, val) => {
      state.brushColor = val;
      state.updateCounter++;
    },
    setColorMode: (state, val) => {
      state.colorMode = val;
    },
    setIsColorSchemaUpdated: (state, val) => {
      state.isColorSchemaUpdated = val;
    },
  },

  actions: {},
};
